import React from "react";
import s from './HeaderMain.module.scss';
import { useSelector } from "react-redux";


const HeaderMain = () => {
    const first_name = useSelector((state) => state.counter.first_name);
    return(
        <div className={s.headerMain}>
            <div className={s.beta}>PigPoke Beta</div>
            <div className={s.welcome}>
                Привет, {first_name}! 
            </div>
            <div className={s.description}>
                Заходи каждый день. Выбирай мешок. Пополняй копилку. 
            </div>
        </div>
    )
}

export default HeaderMain;
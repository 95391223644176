import React from "react";
import s from './Home.module.scss';
import Header from "./Header/Header";
import Content from "./Content/Content";

const Home = () => {

    return(
     <div className={s.home}>
           <Header />
           <Content />
      </div> 
    )
}

export default Home;



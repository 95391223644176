import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {useSelector} from 'react-redux';
import s from './Drop.module.scss';

const Drop = () => {
  const [isVisible, setIsVisible] = useState(true);

  const incrementValue = useSelector((state) => state.counter.incrementValue);

  // Удаление компонента через 3 секунды
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 10);

    // Очистка таймаута при размонтировании компонента
    return () => clearTimeout(timeoutId);
  }, []);

  const animationVariants = {
    visible: { y: 0, opacity: 1 },
    hidden: { y: -300, opacity: 0 },
  };

  return (
    <motion.div
      animate={isVisible ? 'visible' : 'hidden'}
      variants={animationVariants}
      transition={{ duration: 1, ease: 'easeInOut' }}
    >
      {/* Содержимое компонента */}
      <div className={s.sumAward}>{incrementValue }<span> $PIG</span></div>
    </motion.div>
  );
};

export default Drop;
import React , { useEffect, useState , useContext, useRef, useCallback } from "react";
import s from './Friends.module.scss';
import { useTelegram } from '../../hoc/useTelegram';
import { useDispatch } from "react-redux";
import { SocketContext } from "../../services/socket";

const Friends = () => {
    const {tg, user, onClose} = useTelegram();


    const urlLink = 'https://t.me/share/url?url=https://t.me/PigPoke_bot/app' + '?startapp=' + user?.id

    const [referrals, setReferrals] = useState([])

    const socket = useContext(SocketContext); 
    const dispatch = useDispatch(); 

    function openTelegramShare(url) {
      window.open(urlLink);
    }

    useEffect(() => {
      const interval = setInterval(() => {
          if (socket) {
              clearInterval(interval);
              socket.emit('getListInviteReferral')
          }
      },100)

      return () => {
          if (interval) {
              clearInterval(interval);
          }
      } 
  },[socket]);

  useEffect(()=> {
    if (socket) {
        socket.on('listInviteReferral', (data) => {
          setReferrals(data)
        })
    }
  })

    return(
     <div className={s.friends}>
            <div className={s.friendsHeader}>Приглашай и зарабатывай</div>
            <div onClick={() => openTelegramShare()} className={s.invite}>Отправить ссылку</div>
        
            <div className={s.list_friend}>
                <div className={s.nameSection}>
                    Твои рефералы <span>({referrals.length})</span>
                </div>
                {
                    referrals.map((ref) => {
                           const dateVisit = new Date(ref.last_visit)
                           let d = dateVisit.getDay().toString();
                           let m = dateVisit.getMonth().toString();
                           let y = dateVisit.getFullYear().toString();
                           if(d.length < 2){
                            d = '0' + d;
                        }
                        if(m.length < 2){
                            m = '0' + m;
                        } 
                        const count_pig = new Intl.NumberFormat('ru-RU').format(ref.game_score) ;
                           return (
                                  <div key={ref.uuid}  className={s.friend}>
                                        <div className={s.desc}>
                                            <div className={s.name}>{ref.first_name} {ref.last_name}</div>
                                            <div className={s.visit}>Заходил {d}.{m}.{y}</div>
                                        </div>
                                        <div >
                                            <div className={s.balance}>{count_pig} $PIG</div>
                                        </div>
                                  </div>
                           )
                        }) 
                }
            
            </div>
      </div> 
    )
}

export default Friends;




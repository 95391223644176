import React, { useEffect, useState , useContext } from "react";
import s from './PlaceBoxes.module.scss';
import {useDispatch,useSelector} from 'react-redux';
import { updateFreePoke } from "../../../../../store/reducers/counterReducers";
import { SocketContext } from "../../../../../services/socket";
import Boxes from "./Boxes/Boxes";


const PlaceBoxes = () => {

   
    const socket = useContext(SocketContext);

    const [type, setType] = useState(null);
    
    const common = useSelector((state) => state.counter.common);
    const uncommon = useSelector((state) => state.counter.uncommon);
    const rare = useSelector((state) => state.counter.rare);
    const epic = useSelector((state) => state.counter.epic);
    const legendary = useSelector((state) => state.counter.legendary);

    const dispatch = useDispatch();    

    useEffect(()=> {

        if (socket) {

            socket.on('resFreePoke', (data) => {
                const payload = {
                    common: data.common,
                    next_poke: data.next_poke,
                    free_poke: data.free_poke
                }
                dispatch(updateFreePoke(payload));
            })
        }
    })

    const chooseType = (button_id) => {
        setType(type === button_id ? null : button_id);
    }

    const boxes = type === null ? null :  <Boxes type={type}/>

    return(
        <div className={s.placeboxes}>
            
         

            <div className={s.iconsBoxes}>
                <div onClick={() => chooseType(0)} className={s.iconBox}>
                <div className={s.img_0}></div><span className={s.countBox}>{common}</span>
                </div>
                <div onClick={() => chooseType(1)} className={s.iconBox} >
                    <div className={s.img_1}></div><span className={s.countBox}>{uncommon}</span>
                </div>
                <div onClick={() => chooseType(2)} className={s.iconBox}>
                    <div className={s.img_2}></div><span className={s.countBox}>{rare}</span>
                </div>
                <div onClick={() => chooseType(3)} className={s.iconBox}>
                    <div className={s.img_3}></div><span className={s.countBox}>{epic}</span>
                </div>
                <div onClick={() => chooseType(4)} className={s.iconBox}>
                    <div className={s.img_4}></div><span className={s.countBox}>{legendary}</span>
                </div>
            </div>
            {boxes}
     
        </div>
    )
}

export default PlaceBoxes;

import React from "react";
import s from './Content.module.scss'
import BlockGame from "./BlockGame/BlockGame";
import FooterGame from "./FooterGame/FooterGame";
import {  Routes, Route } from 'react-router-dom';

const Content = () => {
    return(
        <div className={s.content}>
        
                   <BlockGame />
        </div>
    )
}

export default Content;
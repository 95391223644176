import React, { createContext, useState, useEffect } from "react";
import io from "socket.io-client";
import { useTelegram } from "../hoc/useTelegram";

const SocketContext = createContext(null);

const SocketProvider = ({ children }) => {

  const {tg, user} = useTelegram();

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io("https://pokestore.ru:3001",{
        query: {
          userId: user?.id
          , username: user?.username
          , first_name: user?.first_name
          , last_name: user?.last_name
          , ref_link: tg.initDataUnsafe?.start_param 
      }
    });
    setSocket(newSocket);

    return () => newSocket.disconnect(); // Закройте соединение при отмонтировании
  }, []);
  const main = user?.id ? children : children
  return (
    <SocketContext.Provider value={socket}>
      {main}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };

import React,  { useState, useEffect, useContext } from "react";
import s from './Tasks.module.scss';
import ModalStreak from "./ModalStreak/ModalStreak";
import AwardsReferral from "./AwardsReferral/AwardsReferral";
import CommonTask from "./CommonTask/CommonTask";
import Drop from "./Drop/Drop";

const Tasks = () => {

    const [view, setView] = useState(1);

    const changeView = (prm) => {
        setView(prm)
    }
    const activeNav1 = view == 1 ? s.activeNav : s.notActiveNav
    const activeNav2 = view == 2 ? s.activeNav : s.notActiveNav
    return(
        <div className={s.tasks}>
            <div className={s.taskHeader}>Задания</div>
            <div className={s.taskDesk}>Выполняй задания, чтобы получить мешочки и $PIG</div>
            <div className={s.taskNav}>
                <div onClick={() => changeView(1)} className={activeNav1}>Общие</div>
                <div onClick={() => changeView(2)} className={activeNav2}>Прогресс</div>
            </div>
            {
                view == 1 && <CommonTask />
            }
        
            {
                view == 2 && <ModalStreak />
            }
            {
                view == 2 && <AwardsReferral />
            }
          
        </div>
    )
}

export default Tasks;
import { createSlice  } from "@reduxjs/toolkit"; 


const initialState = { 
            score: 0
            , incrementValue: null
            , username: null
            , first_name: null
            , streak: null
            , free_poke: null
            , next_poke: null
            , last_streak_received: null
            , common: 0
            , uncommon: 0
            , rare: 0
            , epic: 0
            , legendary: 0
            , last_ref_received: null
        };

const counterReducer = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        getUserInfo(state, action) {
            state.score = action.payload.counter;
            state.incrementValue = action.payload.value;
            state.username = action.payload.username;
            state.first_name = action.payload.first_name;
            state.streak = action.payload.streak;
            state.free_poke = action.payload.free_poke;
            state.next_poke = action.payload.next_poke;
            state.common = action.payload.common;
            state.uncommon = action.payload.uncommon;
            state.rare = action.payload.rare;
            state.epic = action.payload.epic;
            state.legendary = action.payload.legendary;
            state.last_streak_received = action.payload.last_streak_received;
            state.last_ref_received = action.payload.last_ref_received
        }, 
        incrementCounter(state, action) {
            state.score = action.payload.counter;
            state.incrementValue = action.payload.value;
            state.common = action.payload.common;
            state.uncommon = action.payload.uncommon;
            state.rare = action.payload.rare;
            state.epic = action.payload.epic;
            state.legendary = action.payload.legendary
        },
        incrementCounterClick(state, action) {
            
            state.incrementValue = action.payload.value;
          
        },
        updateFreePoke(state, action) {
            state.common = action.payload.common;
            state.next_poke = action.payload.next_poke;
            state.free_poke = action.payload.free_poke;
        },
        updateStreakPoke(state, action) {
            state.common = action.payload.common;
            state.uncommon = action.payload.uncommon;
            state.rare = action.payload.rare;
            state.epic = action.payload.epic;
            state.legendary = action.payload.legendary;
            state.last_streak_received = action.payload.last_streak_received;
        },
        updateReferralPoke(state, action) {
            state.common = action.payload.common;
            state.uncommon = action.payload.uncommon;
            state.rare = action.payload.rare;
            state.epic = action.payload.epic;
            state.legendary = action.payload.legendary;
            state.last_ref_received = action.payload.last_ref_received;
        }
    }
}
)

export const {getUserInfo, incrementCounter, incrementCounterClick,updateFreePoke, updateStreakPoke, updateReferralPoke} = counterReducer.actions;

export default counterReducer.reducer;


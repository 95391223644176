import React from "react";
import s from './Header.module.scss';

import HeaderMain from "./HeaderMain/HeaderMain";
import HeaderBottom from "./HeaderBottom/HeaderBottom";


const Header = () => {
    return(
        <div className={s.header}>
        
          <HeaderMain />
          <HeaderBottom />
        </div>
    )
}

export default Header;
import React, { useEffect, useState , useContext, useRef, useCallback } from "react";
import s from './ModalStreak.module.scss';
import { SocketContext } from "../../../services/socket";
import { useDispatch, useSelector } from "react-redux";
import { updateStreakPoke } from "../../../store/reducers/counterReducers";

const ModalStreak = () => {


    const [awards, setAwards] = useState([]); 
    const socket = useContext(SocketContext); 
    const streakState = useSelector((state) => state.counter.streak);
    const lastStreakState = useSelector((state) => state.counter.last_streak_received);
    const dispatch = useDispatch(); 

    const [indexPoke, setIndexPoke] = useState(null); 
    const [countAwardPoke, setcountAwardPoke] = useState(null); 
    
    useEffect(() => {
        const interval = setInterval(() => {
            if (socket) {
                clearInterval(interval);
                socket.emit('getAwardsStreak')
            }
        },100)

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        } 
    },[socket]);

    useEffect(()=> {
        if (socket) {
            socket.on('listAwardsStreak', (data) => {
                setAwards(data)
                console.log(awards)
            })

            socket.on('resStreakAward', (data) => {
                const payload = {
                    common: data.common,
                    uncommon: data.uncommon,
                    rare: data.rare,
                    epic: data.epic,
                    legendary: data.legendary,
                    last_streak_received: data.last_streak_received
                }
                setIndexPoke(data.indexTypePoke);
                setcountAwardPoke(data.countNewPoke);
                dispatch(updateStreakPoke(payload));
            })
        }
        
    })

    const scrollRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
  
    const startScrolling = (event) => {
        if (scrollRef.current.contains(event.target)) {
      setStartX(event.clientX);
      setIsDragging(true);
        }
    };
  
    const continueScrolling = (event) => {
      if (!isDragging) return;
      const deltaX = startX - event.clientX;
      scrollRef.current.scrollLeft += deltaX; 
      setStartX(event.clientX);
    };
  
    const stopScrolling = () => {
      setIsDragging(false);
    };
  
    useEffect(() => {
       
        scrollRef.current.addEventListener('mousedown', startScrolling);
        
        window.addEventListener('mousemove', continueScrolling);
        window.addEventListener('mouseup', stopScrolling);
  
      return () => {
        if (scrollRef.current) {
        scrollRef.current.removeEventListener('mousedown', startScrolling);
        }
        window.removeEventListener('mousemove', continueScrolling);
        window.removeEventListener('mouseup', stopScrolling);
      };
  }, [startScrolling, continueScrolling, stopScrolling]);

  const handleIconClick = () => { 
    if (socket) {
        socket.emit('getStreakAward')
    }
};

    const typeDrop = [
        <div className={s.nameDrop}>Твой дроп: <span className={s.commonDrop}>Обычный мешочек </span><span>x{countAwardPoke}</span></div>,
        <div className={s.nameDrop}>Твой дроп: <span className={s.uncommonDrop}>Необычный мешочек </span><span>x{countAwardPoke}</span></div>,
        <div className={s.nameDrop}>Твой дроп: <span className={s.rareDrop}>Редкий мешочек </span><span>x{countAwardPoke}</span></div>,
        <div className={s.nameDrop}>Твой дроп: <span className={s.epicDrop}>Эпический мешочек </span><span>x{countAwardPoke}</span></div>,
        <div className={s.nameDrop}>Твой дроп: <span className={s.legendaryDrop}>Легендарный мешочек </span><span>x{countAwardPoke}</span></div>
    ]
    let contentDrop = indexPoke == null ? null : typeDrop[indexPoke]
 
    let contentAvailableStreak = streakState == lastStreakState ? 0 : streakState - lastStreakState

    return(
        <div className={s.modal_streak}>
            <div className={s.headerStreak}>
                Награды за стрик <span className={s.countAwards}>{contentAvailableStreak}</span>
            </div>
            <div className={s.modal_desc}>Может выпасть только один предмет</div>
            <div className={s.listStreak} ref={scrollRef}  onMouseDown={startScrolling}>
               {
                    awards.map((award) => {
                       const common = award.common != null ? (<div className={s.common}>Обычный <span className={s.countAward}>x{award.common}</span></div>) : null ;
                       const uncommon = award.uncommon != null ? (<div className={s.uncommon}>Необычный <span className={s.countAward}>x{award.uncommon}</span></div>) : null ;
                       const rare = award.rare != null ? (<div className={s.rare}>Редкий <span className={s.countAward}>x{award.rare}</span ></div>) : null ;
                       const epic = award.epic != null ? (<div className={s.epic}>Эпический <span className={s.countAward}>x{award.epic}</span></div>) : null ;
                       const legendary = award.legendary != null ? (<div className={s.legendary}>Легендарный <span className={s.countAward}>x{award.legendary}</span></div>) : null ;
                       const received =
                                    Number(lastStreakState) >= Number(award.num_streak) && Number(award.num_streak) == 31 && Number(lastStreakState) != streakState
                                    ? <div className={s.get} onClick={handleIconClick}>Забрать </div> :
                                    Number(lastStreakState) >= Number(award.num_streak) 
                                    ? <div className={s.received}>Получено</div> :
                                    streakState - lastStreakState > 0 && Number(lastStreakState) + 1 == Number(award.num_streak) 
                                    ? <div className={s.get} onClick={handleIconClick}>Забрать </div> 
                                    : null;
                       const styleActiveStreak = 
                       Number(lastStreakState) >= Number(award.num_streak) && Number(award.num_streak) == 31 && Number(lastStreakState) != streakState 
                       ? s.itemStreakActive :
                                    award.num_streak === streakState    
                                    ? s.itemStreakActive :
                                    Number(lastStreakState) >= Number(award.num_streak)
                                    ?  s.itemStreakReceived : s.itemStreak;
                            const days = award.num_streak == 31 ? 'Далее' : 'День ' + award.num_streak
                           return (
                            <div key={award.num_streak} className={styleActiveStreak}>
                                 <div className={s.day}>{days}</div>
                
                                 {common}
                                 {uncommon}
                                 {rare}
                                 {epic}
                                 {legendary}
                                 {received}
                            </div>
                            
                           )
                           
                        }) 
                }
            </div>
            <div className={s.headerStreak}>
                {contentDrop}
            </div>
   
        </div>
    )
}

export default ModalStreak;
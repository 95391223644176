import React ,  { useState, useEffect, useContext } from "react";
import s from './BlockGame.module.scss'
import PlaceBoxes from "./PlaceBoxes/PlaceBoxes";
import { useSelector } from "react-redux";
import { SocketContext } from "../../../../services/socket";


const BlockGame = () => {

    const socket = useContext(SocketContext);

    const [timeLeft, setTimeLeft] = useState(null);
    const next_poke = useSelector((state) => state.counter.next_poke); 

    const handleIconClick = (iconId) => {
        if (socket) {
            socket.emit('freePoke', iconId)
        }
    };

    useEffect(() => {
        const targetDate = new Date(next_poke).getTime(); // Преобразуем в миллисекунды
        let interval; 
        // Функция для обновления оставшегося времени
        const updateCountdown = () => {
            const now = new Date().getTime(); // Текущее время в миллисекундах
            const difference = targetDate - now; // Разница между целевой датой и текущим временем

            if (difference <= 0) {
                setTimeLeft(0); // Устанавливаем 0, если время истекло
                clearInterval(interval); // Очищаем интервал, так как отсчет завершен
            } else {
                setTimeLeft(Math.floor(difference / 1000)); // Устанавливаем оставшееся время в секундах
            }
        };

        updateCountdown(); // Первая инициализация
        interval = setInterval(updateCountdown, 1000); // Обновление каждую секунду

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(interval);
    }, [next_poke]); // В зависимости от целевой даты

    // Преобразуем оставшееся время в часы, минуты и секунды
    const hoursLeft = Math.floor(timeLeft / 3600);
    const minutesLeft = Math.floor((timeLeft % 3600) / 60);
    const secondsLeft = timeLeft % 60;
    let contentTime 
    if (timeLeft == 0 ) {
        contentTime =<div className={s.countPoke} onClick={() => handleIconClick()}>Забрать</div>
    } else {
        contentTime = <div className={s.countPoke2}>{hoursLeft.toString().padStart(2, '0')}:{minutesLeft.toString().padStart(2, '0')}:{secondsLeft.toString().padStart(2, '0')}</div>
    }

    return(
    
        <div className={s.blockgame}>
                <div className={s.headerBox}>
                    Мешок на выбор
                    {contentTime}
                </div>
                
                <div className={s.boxes}>
                   
                      <PlaceBoxes />
                    
                </div>
                
        </div>
    )
}

export default BlockGame;
import React , { useEffect, useState , useContext, useRef, useCallback } from "react";
import s from './News.module.scss';
import { useTelegram } from '../../hoc/useTelegram';


const News = () => {

      const {tg, user, onClose} = useTelegram();


      const urlLink = 'https://t.me/share/url?url=https://t.me/PigPoke_bot/app' + '?startapp=' + user?.id
 
      function openTelegramShare(url) {
        window.open(urlLink);
      }
     
      
    return(
     <div className={s.news}>
    
            <h3>Твой id: {user?.id}</h3>
            <h3>Твой никнейм: {user?.username}</h3>
            <h3>Имя: {user?.first_name}</h3>
            <h3>Фамилия: {user?.last_name}</h3>
            <div>Твоя реферальная ссылка от {tg.initDataUnsafe?.start_param}</div>

        
      </div> 
    )
}

export default News;




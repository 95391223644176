import React from "react";
import s from './CommonTask.module.scss';

const CommonTask = () => {
    return(
        <div className={s.commonTask}>
             <div className={s.task}>
                <div className={s.desc}>
                    <div className={s.name}>
                    Подпишись на PigPoke в Telegram 
                    </div>
                    <div className={s.award}>
                    + 10 000 $PIG
                    </div>
                </div>
                <div className={s.execute}>
                    Будет позже
                </div>
             </div>
             <div className={s.task}>
                <div className={s.desc}>
                    <div className={s.name}>
                    Подключить кошелек
                    </div>
                    <div className={s.award}>
                    Редкий мешочек x 1
                    </div>
                </div>
                <div className={s.execute}>
                    Будет позже
                </div>
             </div>
           
        </div>
    )
}

export default CommonTask;
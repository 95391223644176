import React , { useEffect, useState , useContext } from "react";
import s from './HeaderBottom.module.scss';
import {useSelector} from 'react-redux';

const HeaderBottom = () => {


    const count = new Intl.NumberFormat('ru-RU').format(useSelector((state) => state.counter.score)) ;

    const streak = useSelector((state) => state.counter.streak);

  

    return(
        <div className={s.headerBottom}>
            <div className={s.bank } >КОПИЛКА</div>
            <div className={s.bank_score}>{count} <span className={s.pig}>$PIG</span> </div>
            <div className={s.countStreak} > 
                    Стрик дней: {streak}
            </div>
        </div>
    )
}

export default HeaderBottom;
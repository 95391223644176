import {configureStore , combineReducers} from '@reduxjs/toolkit';
import counterReducer from './reducers/counterReducers';
import authSlice from './reducers/authSlice';



const reducer = combineReducers({
    counter: counterReducer,
    auth: authSlice
})

const store = configureStore({
    reducer,
});

export default store;
